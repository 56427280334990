<template>
<!-- <div class="outer" ref="scroll"> -->
<!-- <div class="pulldown-wrapper">
            <div v-show="beforePullDown">
                <span>下拉刷新</span>
            </div>
            <div v-show="!beforePullDown">
                <div v-show="isPullingDown">
                    <span>加载中...</span>
                </div>
                <div v-show="!isPullingDown">
                    <span>刷新成功</span>
                </div>
            </div>
        </div> -->
<div class="container">
  <div class="detail-box">
    <van-tabs v-if="tabList.length" v-model="activeName" :ellipsis="false" color="#0083f5" @click="handleClick" title-active-color="#0083f5" line-width="1.875rem" line-height="0.25rem">
      <van-tab v-for="(item, index) in tabList" :key="index" :name="index+''" :title="item.name">
      </van-tab>
    </van-tabs>
    <swiper :options="swiperOption" :auto-update="true" :auto-destroy="true" ref="Swiper" style="width:100%;height:calc(100% - 41px);">
      <swiper-slide v-for="(item, index) in tabList" :key="index">
        <div class="inner" ref="scroll1">
          <div class="inner-box" v-for="(item1, index1) in item.list" :key="index1">
            <div class="detail-title" v-if="object_pid != 7">
              <img class="detail-title__icon" src="/img/list/title.png" alt="">
              <div class="detail-title__name">{{ item1.name }}</div>
              <div class="detail-title__score">（分值：{{ parseInt(item1.score) }}分）</div>
            </div>
            <div class="detail-inner" v-if="!item2.jumped" v-for="(item2, index2) in item1.list" :key="index2">
              <template>
                <div class="title">{{ item2.name }}</div>
                <div class="score-box">
                  <div class="score-box__content1" @click="showHelp(item2)">
                    <el-tag style="margin-right: 4px;width:66px;text-align:center;" size="small">
                      <span v-if="item2.type == 3">多选题</span>
                      <span v-if="item2.type == 2">单选题</span>
                      <span v-if="item2.type == 1">选填数字</span>
                    </el-tag>
                    如何评估？
                  </div>
                  <template v-if="inspect_status.type == 1">
                    <template v-if="role == 4">
                      <div class="score-box__content2" v-if="item2.real_score || pageType == 1">得分:
                        {{ item2.real_score !== '' ? item2.real_score + '分' : '' }}</div>
                      <div class="score-box__content2--disabled" v-else>未自评</div>
                    </template>
                    <template v-else>
                      <template v-if="isSelf">
                        <div></div>
                      </template>
                      <template v-else>
                        <div class="score-box__content2" v-if="item2.real_score !== '' || isSelf">得分:
                          {{ item2.real_score !== '' ? item2.real_score + '分' : '' }}</div>
                        <div class="score-box__content2--disabled" v-else>未自评</div>
                      </template>
                    </template>
                  </template>
                </div>
                <!-- 打分&单选 -->
                <template v-if="item2.type != 3">
                  <!-- 选分 -->
                  <div class="test-box" v-for="(item3, index3) in item2.options" :key="index3">
                    <div class="test-box__content" v-if="item2.type == 1">{{ item3.name }}</div>
                    <template v-if="item2.type == 1">
                      <el-select v-model="item3.real_score" :disabled="canDisabled" placeholder="评分" @change="selectChange(item2, item3)">
                        <el-option v-for="sel in item3.scoreList" :key="sel" :label="sel + '分'" :value="sel">
                        </el-option>
                      </el-select>
                    </template>
                    <!-- 单选 -->
                    <template v-else-if="item2.type == 2">
                      <el-radio :disabled="canDisabled" :value="item2.radio" :label="item3.option_id" @change="radioChange(item2, item3)">
                        {{ item3.name }}</el-radio>
                    </template>
                  </div>
                </template>
                <!-- 多选 -->
                <template v-else>
                  <div class="test-box">
                    <!-- <div class="test-box__content" v-if="item3.type == 1">{{item3.name}}</div> -->
                    <el-checkbox-group :disabled="canDisabled" v-model="item2.checkbox" @change="handleChecked(item2)">
                      <el-checkbox v-for="(item3, index3) in item2.options" :key="index3" :label="item3.option_id">{{ item3.name }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </template>
                <!-- 上传文件 -->
                <div class="upload-box" v-if="inspect_status.type == 1">
                  <template v-if="role == 4">
                    <div class="upload-box__inner" v-if="pageType == 1">
                      <div class="upload-box__title">证明材料<span>（可上传文档、图片、视频）</span></div>
                      <div class="upload-box__btn" @click="toUpload(item2, 'upload')">上传</div>
                    </div>
                    <div class="upload-box__inner" v-if="pageType != 1">
                      <div class="upload-box__title">证明材料</div>
                      <div class="upload-box__btn" @click="toUpload(item2, 'watch')" v-if="item2.files.length > 0">查看</div>
                      <div class="upload-box__btn--disabled" v-else>未上传</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="upload-box__inner" v-if="pageType != 3 && isSelf">
                      <div class="upload-box__title">证明材料<span>（可上传文档、图片、视频）</span></div>
                      <div class="upload-box__btn" @click="toUpload(item2, 'upload')">上传</div>
                    </div>
                    <div class="upload-box__inner" v-else>
                      <div class="upload-box__title">证明材料</div>
                      <div class="upload-box__btn" @click="toUpload(item2, 'watch')" v-if="item2.files.length > 0">查看</div>
                      <div class="upload-box__btn--disabled" v-else>未上传</div>
                    </div>
                  </template>
                </div>
                <!-- 评审 -->
                <div class="upload-box" v-if="inspectStatus == 4 && role != 4">
                  <div class="upload-box__inner" style="flex-direction: column; margin-top: 0">
                    <div class="upload-box__title" v-if="inspect_status.type == 1" style="margin-top: 15px">评审结果：<span style="color: #F7203E; font-size: 17px; font-weight: 500;">{{ item2.examine_score !== '' ? item2.examine_score + '分' : '未评审' }}</span>
                    </div>
                    <div class="upload-box__title" v-else style="margin-top: 15px">评审结果：<span style="color: #F7203E; font-size: 17px; font-weight: 500;">{{ item2.real_score !== '' ? item2.real_score + '分' : '未评审' }}</span>
                    </div>
                    <div class="upload-box__title" style="margin-top: 15px" v-if="item2.examine_content">
                      评审说明：
                      <span style="color: #F7203E; font-size: 17px; font-weight: 500;">{{ item2.examine_content}}</span>
                    </div>
                  </div>
                </div>
                <!-- 督导他评 -->
                <div class="upload-box" v-if="inspectStatus == 3 && inspect_status.type == 1 && role != 4">
                  <div class="upload-box__inner" style="flex-direction: column; margin-top: 0">
                    <div class="upload-box__title" style="margin-top: 15px">
                      评审结果：
                      <el-select class="admin-select" size="mini" v-model="item2.examine_score" placeholder="点击评分">
                        <el-option v-for="sel2 of item2.exOption" :key="sel2" :label="sel2 + '分'" :value="sel2">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="upload-box__title" style="margin-top: 15px">
                      评审说明： <el-input type="textarea" :rows="2" placeholder="请输入内容" maxlength="200" show-word-limit v-model="item2.examine_content" style="margin-top: 15px">
                      </el-input>
                    </div>
                  </div>
                </div>
                <!-- 督导自评 -->
                <div class="upload-box" v-if="inspectStatus == 3 && inspect_status.type != 1 && role != 4">
                  <div class="upload-box__inner" style="flex-direction: column; margin-top: 0">
                    <div class="upload-box__title" style="margin-top: 15px">评审结果：<span style="color: #F7203E; font-size: 17px; font-weight: 500;">{{ item2.real_score !== '' ? item2.real_score + '分' : '' }}</span>
                    </div>
                    <div class="upload-box__title" style="margin-top: 15px">
                      评审说明： <el-input @blur="contentBlur(item2)" type="textarea" :rows="2" placeholder="请输入内容" maxlength="200" show-word-limit v-model="item2.examine_content" style="margin-top: 15px">
                      </el-input>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- <div class="nodata-box">
                            <img src="/img/main/nodata.png" alt="">
                            <div class="nodata-desc">暂无内容~ </div>
                        </div> -->
        </div>
      </swiper-slide>
    </swiper>
  </div>
  <template v-if="showBtn">
    <div class="btn-box" style="flex-direction: column" v-if="isSelf">
      <el-button class="login-btn" @click="saveAll" :disabled="!canCheck" type="primary" v-throttle>保存
      </el-button>
    </div>
    <div class="btn-box" style="flex-direction: column" v-else>
      <el-button class="login-btn" @click="saveExamine" :disabled="!canCheck" type="primary" v-throttle>保存
      </el-button>
      <!-- <div class="footer">北京京学科技发展集团有限公司提供技术支持</div> -->
    </div>
  </template>
  <van-overlay :lock-scroll="false" :show="dialogVisible" @click="dialogVisible = false">
    <div class="pinggu_content" @click.stop>
      <div class="close" @click="dialogVisible = false"></div>
      <p style="line-height: 2;font-size: 18px;padding:0 30px 0;text-align:center;font-weight:bold;">如何评估</p>
      <div class="pinggu_content_main">
        <p style="margin: 7px 0;line-height: 1;font-size: 16px;padding:0 30px 0;text-align:left;font-weight:bold;" v-if="dialogText1">评估方式与信息采集：</p>
        <div v-if="dialogText1" v-html="dialogText1" style="font-size: 14px;line-height: 1.5;padding:0 30px 10px;text-align:left;"></div>
        <p style="margin: 7px 0;line-height: 1;padding:0 30px 0;font-size: 16px;text-align:left;font-weight:bold;" v-if="dialogText1">解释说明：</p>
        <div v-if="dialogText2" v-html="dialogText2" style="line-height: 1.5;font-size: 14px;padding:0 30px 10px;text-align:left;;"></div>
      </div>
    </div>
  </van-overlay>
</div>
<!-- </div> -->
</template>

<script>
import {
  Swiper,
  SwiperSlide,
  directive
} from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  name: "Detail",
  data() {
    const that = this;
    return {
      dialogVisible: false,
      dialogText1: '',
      dialogText2: '',
      canDisabled: '',
      isSelf: '',
      isNew: '',
      role: '',
      showBtn: true,
      pageType: '',
      subArr: [],
      checkList: [],
      radio: 0,
      inspect_id: '',
      object_pid: '',
      tabList: [],
      jumpList: {},
      jump_ids: [],
      testscore: 0,
      showModel: false,
      canCheck: true,
      beforePullDown: true,
      isPullingDown: false,
      activeName: 0,
      inspect_status: '',
      nowIndex: 0,
      jumpQid: [],
      swiperOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        direction: "horizontal",
        on: {
          slideChange: function () {
            //这个是当swiper被改变是的回调函数，可以拿到当前索引
            that.activeName = this.activeIndex.toString()
            console.log(that, that.activeName)
          }
        }
      },
    };
  },
  computed: {
    inspectStatus() {
      if (this.inspect_status) {
        let {
          submit_status,
          examine_status,
          type
        } = this.inspect_status;
        let isSchoolType = localStorage.getItem("role") == 4;
        if (submit_status == 0 && examine_status == 0) {
          return 1
        } else if (submit_status == 1 && examine_status == 0) {
          return 2
        } else if (submit_status == 1 && examine_status == 2) {
          return 3
        } else if (submit_status == 1 && examine_status == 1) {
          return 4
        }
      } else {
        return 0
      }
    },
    readOnly() {
      if (this.inspect_status) {
        return (this.inspect_status.submit_status == 1 && localStorage.getItem("role") == 4) ||
          (this.inspect_status.examine_status == 1 && localStorage.getItem("role") != 4) ||
          (this.inspect_status.type == 1 && this.inspect_status.examine_status == 0 && localStorage.getItem("role") != 4) ||
          (this.inspect_status.type == 1 && this.inspect_status.examine_status == 2 && localStorage.getItem("role") != 4) ||
          (this.inspect_status.examine_status == 1)
      } else {
        return false
      }
    },
    isAdminType() {
      return this.inspect_status.type == 2 || this.inspect_status.type == 3
    },
    isSchoolType() {
      return this.inspect_status.type == 1
    },
    isAdminUser() {
      return localStorage.getItem("role") != 4
    },
    isSchoolUser() {
      return localStorage.getItem("role") == 4
    }
  },

  components: {
    Swiper,
    SwiperSlide
  },
  created() {
    this.object_pid = this.$route.query.pid
    this.inspect_id = this.$route.query.id
    this.pageType = this.$route.query.type
    this.isNew = this.$route.query.is_new
    this.role = localStorage.getItem('role')
    if (this.$route.query.type != 1 && localStorage.getItem('role') == 4 || this.$route.query.type == 3) {
      this.showBtn = false
    }
    if (this.$route.query.type == 1 && this.role != 4) {
      this.showBtn = false
    }
    // console.log(this.$route.query.id, this.$route.query.pid)

  },
  async mounted() {
    await this.getDetail()
    if (this.inspect_status.type == 2 && (this.role == 1 || this.role == 2)) {
      this.showBtn = false
    }
    let tabIndex = sessionStorage.getItem('filesBack') || 0;
    sessionStorage.setItem('filesBack', '');
    this.handleClick(tabIndex);
  },
  methods: {
    contentBlur(q) {
      this.saveQuestion(q);
    },
    async getDetail() {
      let that = this
      let res = await this.$api({
        method: 'get',
        url: '/api/object/info',
        data: {
          inspect_id: this.$route.query.id,
          object_pid: this.$route.query.pid
        }
      })
      console.log(res)
      if (res.code == 0) {
        this.title = res.data.object_pname;
        this.time_id = res.data.time_id;
        // 基础数据
        // 循环到题的一层，添加checkbox value，filelist 字段
        if (this.object_pid == 7) {
          this.tabList = res.data.list.map((e) => {
            let obj = JSON.stringify(e);
            e = {
              name: e.name,
              list: [JSON.parse(obj)],
            };
            for (const e2 of e.list) {
              if (e2.list) {
                for (let e3 of e2.list) {
                  e3.examine_score = '';
                  e3.examine_content = '';
                  e3.jumped = false; // 跳过 
                  e3.real_score = '';
                  e3.imgList = [];
                  e3.videoList = [];
                  e3.fileList = [];
                  e3.examine_content = e3.result_info.length ? e3.result_info[0].content : '';
                  e3 = this.handleOption(e3);
                }
              }
            }
            return e;
          });
        } else {
          this.tabList = res.data.list.map((e1) => {
            for (const e2 of e1.list) {
              if (e2.list) {
                for (let e3 of e2.list) {
                  e3.jumped = false; // 跳过 
                  e3.examine_score = '';
                  e3.examine_content = '';
                  e3.real_score = '';
                  let list = []
                  for (let index = e3.score * 1; index >= 0; index--) {
                    list.push(index)
                  }
                  e3.scoreList = list;
                  e3.examine_content = e3.result_info.length ? e3.result_info[0].content : '';
                  e3 = this.handleOption(e3);
                }
              }
            }
            return e1;
          });
          // 隐藏跳过的题
          this.hideJump();
        }
        this.inspect_status = res.data.inspect || {
          submit_status: 0,
          examine_status: 0,
        };
        this.isSelf = this.judgeSelf(res.data.inspect.type)
        this.canDisabled = this.judgeDisabled(this.judgeSelf(res.data.inspect.type))
        console.log(this.isSelf, this.canDisabled)
        if (this.inspect_status.examine_status == 1) {
          this.showBtn = false
        }
        // console.log(this.tabList);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    judgeSelf(type) {
      return type == 3 && localStorage.getItem('role') == 2 ||
        type == 2 && localStorage.getItem('role') == 3 ||
        type == 1 && localStorage.getItem('role') == 4
    },
    judgeDisabled(isSelf) {
      return this.isNew == 1 ? false : this.pageType == 3 ? true : isSelf ? localStorage.getItem('role') == 4 && this.pageType != 1 ? true : false : true
    },
    // 处理答案
    handleOption(e3) {
      // 选分题
      if (e3.type == 1) {
        let exScore = 0;
        let exOption = [];
        let pipei = [];
        e3.options.forEach(element => {
          element.score = element.score * 1;
          let list = []
          for (let index = element.score; index >= 0; index--) {
            list.push(index)
          }
          element.scoreList = list;
          pipei = e3.result_info.filter(e => {
            return e.option_id == element.option_id
          });
          element.real_score = pipei.length ? pipei[0].score * 1 : '';
          exScore += element.score;
        });
        let s = 0;
        for (const i of e3.options) {
          if (i.real_score) {
            s += i.real_score * 1
          }
        }
        e3.real_score = s || '';
        for (let index = exScore; index >= 0; index--) {
          exOption.push(index);
        }
        e3.exOption = exOption;
      } else if (e3.type == 2) {
        //判断是否是跳过的题
        let jumpFlag = e3.options.filter(e => {
          return e.jump_number != 0;
        });
        if (jumpFlag.length) {
          console.log(jumpFlag, '有跳过的题');
          e3.hasJump = true;
          this.jumpQid.push(e3.number);
        }
        this.jumpQid = Array.from(new Set(this.jumpQid));
        console.log('jumpQid', this.jumpQid)
        // 单选题
        let exOption = [];
        e3.radio = e3.result_info.length ? e3.result_info[0].option_id * 1 : "";
        e3.real_score = e3.result_info.length ? e3.result_info[0].score * 1 : "";
        if (e3.radio) {
          let list = e3.options.filter(e => {
            return e.option_id * 1 == e3.radio * 1
          });
          if (list.length) {
            let jump_number = list[0].jump_number;
            console.log('有跳过的题', jump_number)
            if (jump_number > 0) {
              let ids = [];
              if (jump_number != 0) {
                for (let i = e3.number + 1; i < jump_number; i++) {
                  ids.push(i)
                }
              }
              this.jumpList[e3.number] = ids;
            }
            console.log(this.jumpList)
            let index = this.jumpQid.indexOf(e3.number);
            console.log(index);
            this.jumpQid.splice(index, 1);
            console.log(this.jumpQid);
          }
        }
        e3.options.forEach(element => {
          exOption.push(element.score * 1)
        });
        e3.exOption = Array.from(new Set(exOption));
      } else if (e3.type == 3) {
        let exScore = 0;
        let exOption = [];
        // 多选题
        // 获得之前选择的答案 
        if (e3.result_info.length) {
          e3.checkbox = e3.result_info.length ? e3.result_info.map(e => {
            return e.option_id * 1;
          }) : [];
          // 计算之前选择的答案得分
          let s = 0;
          for (const i of e3.checkbox) {
            let a = e3.options.filter(e => {
              return e.option_id == i
            });
            if (a.length) {
              s += a[0].score * 1;
            }
          }
          e3.real_score = s;
        } else {
          e3.checkbox = [];
        }

        e3.options.forEach(element => {
          element.score = element.score * 1;
          exScore += element.score;
        });
        let s = 0;
        for (const i of e3.options) {
          s += i.real_score * 1;
        }
        for (let index = exScore; index >= 0; index--) {
          exOption.push(index);
        }
        e3.exOption = exOption;
      }
      // 审核结果分
      if (e3.result_info) {
        let examine = e3.result_info.filter(e => {
          return e.type == 2;
        });
        if (examine.length) {
          e3.examine_score = examine[0].score * 1;
          e3.examine_content = examine[0].content;
        }
        return e3
      }
    },
    handleClick(tab) {
      console.log(tab);
      this.activeName = tab.toString();
      this.$refs.Swiper.$swiper.slideTo(tab, 300, false);
      this.$refs.Swiper.swiperOptions.initialSlide = tab;
    },
    toUpload(item2, type) {
      sessionStorage.setItem('filesBack', this.activeName);
      sessionStorage.setItem('files', JSON.stringify(item2.files));
      this.$router.push({
        path: '/upload',
        query: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          object_id: item2.object_id,
          type,
          pageType: this.pageType
        }
      })
    },
    showHelp(q) {
      if (this.role != 4) {
        this.dialogText1 = q.ta_estimate;
        this.dialogText2 = q.ta_explain;
      } else {
        this.dialogText1 = q.estimate;
        this.dialogText2 = q.explain;
      }
      this.dialogVisible = true;
    },
    open() {
      this.showModel = true
      // this.$confirm('是否继续自评？', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     this.$message({
      //         type: 'success',
      //         message: '删除成功!'
      //     });
      // }).catch(() => {
      //     this.$message({
      //         type: 'info',
      //         message: '已取消删除'
      //     });
      // });
    },
    // 多选
    handleChecked(q, a) {
      console.log(q, a)
      let s = 0;
      for (const i of q.checkbox) {
        s += q.options.filter(e => {
          return e.option_id == i
        })[0].score * 1;
      }
      q.real_score = s;
      this.saveQuestion(q)
    },
    // 单选
    radioChange(q, a) {
      if (q.radio == a.option_id) {
        q.radio = '';
        q.real_score = '';
        this.setJumpList(q, 0);
      } else {
        q.radio = a.option_id * 1;
        q.real_score = a.score * 1;
        this.setJumpList(q, a.jump_number);
      }
      this.saveQuestion(q);
    },
    radioChange2(q, a) {
      console.log('radioChange', q.radio == a.option_id);
    },
    // 跳题
    setJumpList(q, jump_number) {
      console.log('jump_number', jump_number)
      if (jump_number == 0) {
        if (this.jumpList[q.number] && this.jumpList[q.number].length) {
          // 有
          let ids = this.jumpList[q.number];
          for (const e1 of this.tabList) {
            for (const e2 of e1.list) {
              for (let e3 of e2.list) {
                if (ids.indexOf(e3.number) != -1) {
                  e3.jumped = false;
                  let index = this.jump_ids.indexOf(e3.object_id);
                  this.jump_ids.splice(index, 1);
                }
              }
            }
          }
          this.jumpList[q.number] = '';
        }
        return;
      } else {
        let ids = [];
        if (jump_number != 0) {
          for (let i = q.number + 1; i < jump_number; i++) {
            ids.push(i)
          }
        }
        this.jumpList[q.number] = ids;
        for (const e1 of this.tabList) {
          for (const e2 of e1.list) {
            for (let e3 of e2.list) {
              e3.jumped = false;
              if (ids.indexOf(e3.number) != -1) {
                e3.jumped = true;
                this.jump_ids.push(e3.object_id);
                e3.checkbox = [];
                e3.radio = '';
                e3.select = '';
                e3.real_score = '';
              }
            }
          }
        }
        this.jump_ids = Array.from(new Set(this.jump_ids))
        console.log(this.jump_ids)
      }
      // 根据问题number查找缓存，是否有跳过的题;

    },
    hideJump() {
      for (const key in this.jumpList) {
        if (Object.hasOwnProperty.call(this.jumpList, key)) {
          const ids = this.jumpList[key];
          for (const e1 of this.tabList) {
            for (const e2 of e1.list) {
              for (let e3 of e2.list) {
                if (ids.indexOf(e3.number) != -1) {
                  e3.jumped = true;
                  this.jump_ids.push(e3.object_id);
                  e3.checkbox = [];
                  e3.radio = '';
                  e3.select = '';
                  e3.real_score = '';
                }
              }
            }
          }
        }
      }
      // console.log(this.jump_ids)
    },
    // 选分
    selectChange(q, a) {
      console.log(q, a);
      let s = 0;
      console.log(q.options)

      for (const i of q.options) {
        s += i.real_score * 1;
      }
      q.real_score = s;
      this.saveQuestion(q);
    },
    async saveExamine() {
      console.log('存测评')
      let length = 0;
      let result_json = [];
      for (const e1 of this.tabList) {
        for (const e2 of e1.list) {
          for (let e3 of e2.list) {
            length++;
            if (e3.examine_score !== '') {
              result_json.push({
                object_id: e3.object_id,
                score: e3.examine_score,
                content: e3.examine_content
              })
            }
          }
        }
      }
      if (result_json.length < length) {
        this.$Dialog.confirm({
            message: `有<b style="color:red">${length - result_json.length}</b>项没有评估，是否保存。`,
            confirmButtonColor: '#0083F5',
            confirmButtonText: '保存',
            cancelButtonText: '继续评估',
            cancelButtonColor: '#999',
          })
          .then(async () => {
            let res = await this.$api({
              method: "post",
              url: "/api/inspect/examine_save",
              data: {
                inspect_id: this.inspect_id,
                object_pid: this.object_pid,
                time_id: this.time_id,
                result_json: result_json.length ? JSON.stringify(result_json) : '',
              }
            });
            if (res.code == 0) {
              this.$message('保存成功');
              this.$router.go(-1);
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            // on cancel
          });
      } else {
        let res = await this.$api({
          method: "post",
          url: "/api/inspect/examine_save",
          data: {
            inspect_id: this.inspect_id,
            object_pid: this.object_pid,
            time_id: this.time_id,
            result_json: result_json.length ? JSON.stringify(result_json) : '',
          }
        });
        if (res.code == 0) {
          this.$message('保存成功');
          this.$router.go(-1);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      }
    },
    async saveAll() {
      console.log('存题')
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          time_id: this.time_id,
          jump_ids: String(this.jump_ids),
          get_number: 1
        }
      });
      if (res.code == 0) {
        this.$message.success('保存成功');
        this.$router.go(-1);
      } else if (res.code == 1) {
        this.$Dialog.confirm({
            message: `有${res.data.not_number}项没有评估，是否保存。`,
            confirmButtonColor: '#0083F5',
            confirmButtonText: '保存',
          })
          .then(async () => {
            this.saveApi(true);
          })
          .catch(() => {
            this.saveApi();
          });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async saveApi(back) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          time_id: this.time_id,
          jump_ids: String(this.jump_ids),
          get_number: 0,
        }
      });
      if (res.code == 0) {
        if (back) {
          this.$message.success('保存成功');
          this.$router.go(-1);
        }
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async saveQuestion(item) {
      let json = [];
      let object_type = item.type; //题型；
      let object_id = item.object_id;
      let options = item.options;

      if (object_type == 3) {
        let checkbox = JSON.parse(JSON.stringify(item.checkbox));
        for (let i of checkbox) {
          let op = options.filter(e => {
            return e.option_id == i;
          })
          json.push({
            score: op[0].score,
            content: item.examine_content,
            option_id: op[0].option_id,
            object_type,
          })
        }
      } else if (object_type == 2) {
        if (item.radio) {
          json.push({
            score: item.real_score,
            content: item.examine_content,
            option_id: item.radio,
            object_type,
          })
        }

      } else if (object_type == 1) {
        let op = options.filter(e => {
          return e.real_score !== '';
        })
        for (let i of op) {
          json.push({
            score: i.real_score,
            content: item.examine_content,
            option_id: i.option_id,
            object_type,
          })
        }
      }
      let res = await this.$api({
        method: "post",
        url: "/api/inspect/single_save",
        data: {
          inspect_id: this.inspect_id,
          object_pid: this.object_pid,
          object_id: object_id,
          jump_ids: String(this.jump_ids),
          result_json: JSON.stringify(json)
        }
      });
      if (res.code == 0) {
        console.log('保存成功', json)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss">
* {
  -webkit-overflow-scrolling: touch;
}

.el-message-box {
  max-height: 80%;
  overflow-y: scroll;
}

.pinggu_content {
  width: 20rem;
  padding-bottom: 10px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff url('../../assets/index/pinggu_bg.png') no-repeat top;
  background-size: 100% 380px;
  border-radius: 8px;

  .pinggu_content_main {
    max-height: 25rem;
    overflow-y: scroll;
  }

  .close {
    width: 28px;
    height: 28px;
    position: absolute;
    right: -4px;
    top: -28px;
    background: url('../../assets/index/close.png') no-repeat center;
    background-size: 100%;
  }
}

.van-overlay {
  z-index: 999;
}

.container {
  height: 100%;

  .detail-box {
    position: absolute;
    top: 0px;
    z-index: 99;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;

    .el-form-item {
      margin-bottom: 16px;
    }

    .el-form-item__content {
      display: flex;
    }

    // .swiper-container {
    //     overflow-y: scroll;
    // }

    .inner {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      padding: 0 15px 15px 15px;
      padding-bottom: 80px;

      .inner-box {
        margin-top: 15px;

        .detail-title {
          display: flex;
          align-items: center;

          .detail-title__icon {
            margin-right: 7px;
            width: 12px;
            height: 10px;
          }

          .detail-title__name {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 18px;
          }

          .detail-title__score {
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            line-height: 13px;
          }
        }

        .detail-inner {
          position: relative;
          padding: 15px;
          margin-top: 15px;
          width: auto;
          background: #FFFFFF;
          box-shadow: 0px 1px 5px 0px rgba(245, 245, 245, 1);
          border-radius: 6px;

          .score-box {
            padding-bottom: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #EEEEEE;

            .score-box__content1 {
              font-size: 15px;
              font-weight: 400;
              color: #0083F5;
              line-height: 15px;
            }

            .score-box__content2 {
              font-size: 17px;
              font-weight: 500;
              color: #0083F5;
              line-height: 17px;
            }

            .score-box__content2--disabled {
              width: 60px;
              height: 26px;
              border-radius: 3px;
              font-size: 14px;
              border: none;
              color: #999999;
              background: #F6F6F6;
              font-weight: 500;
              line-height: 26px;
            }
          }

          .test-box {
            text-align: left;
            margin-top: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .test-box__content {
              text-align: left;
              margin-right: 13px;
              width: 242px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }

            .is-disabled .el-input__inner {
              background: #F6F6F6;
              border: none;
              color: #999999;
            }

            .el-select .el-input {
              input::placeholder {
                color: #0083F5
              }
            }

            .el-select .el-input__inner {
              text-align: center;
              background: #ffffff;
              padding: 0;
              width: 60px;
              height: 26px;
              border-radius: 3px;
              border: 1px solid #0083F5;
              font-size: 14px;
              font-weight: 500;
              color: #0083F5;
              line-height: 26px;
            }

            .el-input__suffix {
              display: none;
            }

            .el-checkbox {
              margin-top: 17px;
              display: flex;
              align-items: center;
            }

            .el-checkbox__label {
              white-space: break-spaces;
            }

            .el-radio__label {
              line-height: 20px;
              white-space: break-spaces;
            }
          }

          .upload-box {
            margin-top: 20px;
            border-top: 1px solid #EEEEEE;

            .is-disabled .el-input__inner {
              background: #F6F6F6;
              border: none;
              color: #999999;
            }

            .el-select .el-input {
              input::placeholder {
                color: #0083F5
              }
            }

            .el-select .el-input__inner {
              text-align: center;
              background: #ffffff;
              padding: 0;
              width: 60px;
              height: 26px;
              border-radius: 3px;
              border: 1px solid #0083F5;
              font-size: 14px;
              font-weight: 500;
              color: #0083F5;
              line-height: 26px;
            }

            .el-input__suffix {
              display: none;
            }

            .el-textarea {
              margin-top: 15px;

              textarea::placeholder {
                font-size: 14px;
                font-weight: 400;
                color: #B0B3BC;
                line-height: 26px;
              }

              .el-textarea__inner {
                background: #F9F9F9;
                border: none;
                min-height: 99px !important;
              }

              .el-input__count {
                background: #F9F9F9;
              }
            }

            .upload-box__inner {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;

              .upload-box__title {
                text-align: left;
                font-size: 15px;
                font-weight: 400;
                color: #333333;
                line-height: 24px;

                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                  line-height: 24px;
                }
              }

              .upload-box__btn {
                width: 60px;
                height: 26px;
                background: #FFFFFF;
                border-radius: 3px;
                border: 1px solid #0083F5;
                font-size: 14px;
                font-weight: 500;
                color: #0083F5;
                line-height: 26px;
              }

              .upload-box__btn--disabled {
                width: 60px;
                height: 26px;
                border-radius: 3px;
                font-size: 14px;
                border: none;
                color: #666;
                // background: #F6F6F6;
                font-weight: 500;
                line-height: 26px;
              }
            }
          }

          .title {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }
        }
      }

      .nodata-box {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 196px;
          height: 119px;
        }

        .nodata-desc {
          font-size: 14px;
          font-weight: 400;
          color: #888888;
          line-height: 21px;
        }
      }
    }
  }

  .btn-box {
    z-index: 100;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 73px;
    background: #FFFFFF;
    box-shadow: 0px -1px 4px 0px rgba(242, 242, 242, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .login-btn {
      width: 323px;
      font-size: 19px;
      color: #ffffff;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-select-dropdown {
  border: 1px solid #0083F5;

  .el-select-dropdown__item {
    text-align: center;
    padding: 0;
    width: 60px;
  }
}

.el-popper .popper__arrow {
  border-bottom-color: #0083F5 !important;
}
</style>
